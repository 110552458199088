<script setup>
import AddressForm from '@/Components/Address/Form.vue';
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { ref } from 'vue';
import DeleteLocationForm from './Partials/DeleteLocationForm.vue';
import LocationUpdateForm from './Partials/LocationUpdateForm.vue';
import TeamMembers from './Partials/TeamMembers.vue';
import QrDownloadForm from './Partials/QrDownloadForm.vue';

const props = defineProps({
    location: Object,
});

const showDeleteLocationForm = ref(false);
const openDeleteLocationForm = () => {
    showDeleteLocationForm.value = true;
};
</script>

<template>
    <StaffLayout :title="location.nickname">
        <LocationUpdateForm :location="location" />

        <FormSingleSettingBar :title="$t('{model} details', { model: $t('Address') })">
            <AddressForm :address="location.address" />
        </FormSingleSettingBar>

        <TeamMembers :team="location.team" :teamable="location" />

        <QrDownloadForm :location="location" />

        <FormSingleSettingBar v-if="location.can_be_deleted" :title="$t('Delete {model}', { model: $t('location') })">
            <div class="grid gap-5">
                <p>
                    {{ $t('If this {model} needs to be removed, use the button below', { model: $t('location') }) }}
                </p>
                <Button
                    danger
                    admin
                    @click="openDeleteLocationForm()"
                    :text="$t('Delete {model}', { model: $t('location') })"
                />
            </div>
        </FormSingleSettingBar>
    </StaffLayout>
    <DeleteLocationForm v-model:showing="showDeleteLocationForm" :location="location" />
</template>
