<script setup>
import Button from '@/Components/Button/Button.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

import { inject } from 'vue';
const { t } = useI18n();
const route = inject('route');
const props = defineProps({
    address: Object,
});

const form = useForm({
    ...props.address,
});

const sendForm = () => {
    form.put(route('staff.address.update', { address: props.address.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const fillAddress = () => {
    if (form.postcode == '' || form.street_number == '') {
        return;
    }
    form.errors = {};
    window.axios
        .get(
            route('api.v0.address-check.index', {
                postcode: form.postcode,
                street_number: form.street_number,
            })
        )
        .then(function (response) {
            form.postcode = response.data.data.postcode;
            form.street_number = response.data.data.street_number;
            form.street_name = response.data.data.street_name;
            form.city = response.data.data.city;
            form.country = 'NL';
            form.longitude = response.data.data.longitude;
            form.latitude = response.data.data.latitude;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 404) {
                    form.errors.postcode = [t('Was unable to find an address with this postcode and street number')];
                }
            }
        });
};
</script>
<template>
    <FormKit type="form" id="addressUpdateForm" @submit="sendForm" :actions="false">
        <div class="grid gap-5">
            <div class="grid gap-4 md:grid-cols-3">
                <FormKit
                    type="text"
                    name="postcode"
                    :label="$t('Postal code')"
                    v-model="form.postcode"
                    :errors="form.errors.postcode"
                    :value="form.postcode"
                    validate="required"
                    @blur="fillAddress()"
                />
                <FormKit
                    type="text"
                    name="street_number"
                    :label="$t('Street Number')"
                    v-model="form.street_number"
                    :errors="form.errors.street_number"
                    :value="form.street_number"
                    @blur="fillAddress()"
                    validate="required"
                />

                <FormKit
                    type="text"
                    name="street_number_addition"
                    :label="$t('Street Number Addition')"
                    v-model="form.street_number_addition"
                    :errors="form.errors.street_number_addition"
                    :value="form.street_number_additions"
                    validate="required"
                />
            </div>

            <div class="grid gap-4 md:grid-cols-2">
                <FormKit
                    type="text"
                    name="street_name"
                    :label="$t('Street name')"
                    v-model="form.street_name"
                    :errors="form.errors.street_name"
                    :value="form.street_name"
                    validate="required"
                />

                <FormKit
                    type="text"
                    name="city"
                    :label="$t('City')"
                    v-model="form.city"
                    :errors="form.errors.city"
                    :value="form.city"
                    validate="required"
                />
            </div>
            <div class="grid gap-4 md:grid-cols-3">
                <FormKit
                    type="text"
                    name="country"
                    :label="$t('Country')"
                    v-model="form.country"
                    :errors="form.errors.country"
                    :value="form.country"
                    validate="required"
                />

                <FormKit
                    type="text"
                    name="longitude"
                    :label="$t('Longitude')"
                    v-model="form.longitude"
                    :errors="form.errors.longitude"
                    :value="form.longitude"
                    validate="required"
                />

                <FormKit
                    type="text"
                    name="latitude"
                    :label="$t('Latitude')"
                    v-model="form.latitude"
                    :errors="form.errors.latitude"
                    :value="form.latitude"
                    validate="required"
                />
            </div>

            <div class="flex justify-end">
                <Button
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    icon="/icon/done-white.svg"
                    :text="$t('Save')"
                />
            </div>
        </div>
    </FormKit>
</template>
